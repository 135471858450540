/**
 * MS-搜索中心接口 3.0
 */

import http from "../utils/fetch";
const prefix = "/api/search";

//自助机-购票【search032】- 李小华
export function productSelfBuyticket(data) {
  return http.get(`${prefix}/product/self/buyticket`, data);
}
// 自助机-订单号查询门票打印【search805】ok
export function orderTicketprintinfo(data) {
  // return http.get(`${prefix}/order/ticketprintinfo`, data);
  return http.get(`${prefix}/order/self/ticketprintinfo/order/id`, data);
}

// 窗口-会员充值【search005】
export function productFrontMemberrecharge(data) {
  return http.get(`${prefix}/product/front/memberrecharge`, data);
}

// 自助机-快速通行服务说明【search035】ok
export function productSelfFastremark(data) {
  return http.get(`${prefix}/product/self/fastremark`, data);
}

// 自助机-门票充值【search039】ok
export function productSelfTicketrecharge(data) {
  return http.get(`${prefix}/product/self/ticketrecharge`, data);
}

// 自助机-项目套餐【search040】ok
export function productSelfProjectPackage(data) {
  return http.get(`${prefix}/product/self/projectPackage`, data);
}

// 自助机-门票查询【search063】ok
export function orderSelfQueryticket(data) {
  return http.get(`${prefix}/order/self/queryticket`, data);
}

// 窗口/后台/自助机-项目套餐可玩项目【search059】ok
export function orderPackageusableProject(data) {
  return http.get(`${prefix}/order/packageusableProject`, data);
}

// 自助机-取票【search074】ok
export function orderSelfTakeTicket(data) {
  return http.get(`${prefix}/order/self/takeTicket`, data);
}

// 自助机-会员充值【search041】ok
export function productSelfMemberrechargepackage(data) {
  return http.get(`${prefix}/product/self/memberrechargepackage`, data);
}

// 自助机-订单详情查询门票打印【search804】ok
export function orderSelfTicketprintinfo(data) {
  // return http.get(`${prefix}/order/self/ticketprintinfo`, data);
  return http.get(`${prefix}/order/self/ticketprintinfo/detail/id`, data);
}

// 自助机-项目消费记录【802】ok
export function orderSelfProjectConsume(data) {
  return http.get(`${prefix}/order/self/projectConsume`, data);
}

// 订单号与打印模板查询项目套餐打印信息【search619】
export function orderProjectpackageprintInfo(data) {
  return http.get(`${prefix}/order/projectpackageprintInfo`, data);
}
//手机管家-产品详情(二)【search1207】- 李小华 http://192.168.1.50:40001/project/41/interface/api/3964
export const orderMobilemanagerProductdetails = data => {
  return http.get(`${prefix}/order/mobilemanager/productdetails`, data);
};
/**
 * 新版
 **/
// 自助机-票务产品【search306】 http://192.168.1.50:40001/project/41/interface/api/4288
export const productSelfTicketproduct = data => {
  return http.get(`${prefix}/product/self/ticketproduct`, data);
};

export const orderMemberProduct = data => {
  return http.get(`${prefix}/order/memberproduct`, data);
};

// 自助机-会员兑换币套餐【search441】  http://192.168.1.50:40001/project/41/interface/api/4420
export const productFrontpackage = data => {
  return http.get(`${prefix}/product/self/exchangecoinpackage`, data);
};
// 自助机-会员兑换券【search441】 http://192.168.1.50:40001/project/41/interface/api/4423
export const productFrontMembervoucher = data => {
  return http.get(`${prefix}/product/self/coincertificate`, data);
};
// 【search646】窗口-会员消费明细 - http://192.168.1.50:40001/project/41/interface/api/4183
export function orderConsumedetail(data) {
  return http.get(`${prefix}/order/consumedetail`, data);
}

// 自助机-聚合产品分类【search309】- http://192.168.1.50:40001/project/41/interface/api/4297
export const searchProductSelfAggregategoryt = data => {
  return http.get(`${prefix}/product/self/aggregatecategory`, data);
};
// 自助机-会员优惠券查看 http://192.168.1.50:40001/project/41/interface/api/3910
export const productFrontCouponapplyProduct = data => {
  return http.get(`${prefix}/product/front/couponapplyproduct`, data);
};

// 自助机-快速门票打印信息【search636】 http://192.168.1.50:40001/project/41/interface/api/3905
export const orderQuickTicketPrint = data => {
  return http.get(`${prefix}/order/self/quickticketprint`, data);
};
// 自助机-组合产品【search307】 http://192.168.1.50:40001/project/41/interface/api/4291
export const productSelfCombinationproduct = data => {
  return http.get(`${prefix}/product/self/combinationproduct`, data);
};
// 自助机-快速零售打印信息【search649】 http://192.168.1.50:40001/project/41/interface/api/4276
export const orderQuickRetailPrint = data => {
  return http.get(`${prefix}/order/quickretailprint`, data);
};
// 自助机-新版取票【search806】 http://192.168.1.50:40001/project/41/interface/api/4300
export const orderSelfFetchTicket = data => {
  return http.get(`${prefix}/order/self/fetchticket`, data);
};
// 自助机-订单详情门票打印【search808】 http://192.168.1.50:40001/project/41/interface/api/4333
export const orderSelfOrderDetailTicketPrint = data => {
  return http.get(`${prefix}/order/self/orderdetailticketprint`, data);
};
