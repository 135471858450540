<template>
  <qmp-layout title="订单详情">
    <div class="main">
      <div class="flex-start item">
        <p class="disabled">总单号：</p>
        <p class="ml-52px">{{ detailData.id }}</p>
      </div>
      <div class="flex-start item">
        <p class="disabled">总单业态集：</p>
        <p class="ml-52px">{{ detailData.orderFormatTypeText }}</p>
      </div>
      <div
        class="flex-start item"
        v-for="(item, index) in detailData.ownerMerchants"
        :key="index"
      >
        <p class="disabled">总单商户集：</p>
        <p class="ml-52px">{{ item.merchantName }}</p>
      </div>
      <div class="flex-start item">
        <p class="disabled">销售员：</p>
        <p class="ml-52px">{{ detailData.salespersonName }}</p>
      </div>
      <div class="flex-start item">
        <p class="disabled">业务类型：</p>
        <p class="ml-52px">{{ detailData.orderBusinessTypeText }}</p>
      </div>
      <div class="flex-start item">
        <p class="disabled">下单时间：</p>
        <p class="ml-52px">
          {{ detailData.createTime | date("yyyy-mm-dd hh:MM:ss") }}
        </p>
      </div>
      <div class="flex-start item">
        <p class="disabled">总单实收：</p>
        <p class="ml-52px">{{ detailData.settlementPrice | money() }}</p>
      </div>
      <div class="flex-start item">
        <p class="disabled">总单退款金额：</p>
        <p class="ml-52px">{{ detailData.totalRefundAmount | money() }}</p>
      </div>
      <div class="flex-start item">
        <p class="disabled">总单实收金额：</p>
        <p class="ml-52px">{{ detailData.totalLeftAmount | money() }}</p>
      </div>
      <div class="flex-start item">
        <p class="disabled">总单优惠活动：</p>
        <p class="ml-52px">{{ detailData.merchantDiscountAmount }}</p>
      </div>
      <div class="flex-start item">
        <p class="disabled">优惠金额：</p>
        <p class="ml-52px">{{ detailData.merchantDiscountAmount }}</p>
      </div>
      <div class="flex-start item">
        <p class="disabled">积分抵扣：</p>
        <p class="ml-52px">{{ detailData.integralDeductionAmount }}</p>
      </div>
      <div class="flex-start item">
        <p class="disabled">支付方式：</p>
        <p class="ml-52px">{{ detailData.payWayName || "" }}</p>
      </div>
      <div class="flex-start item">
        <p class="disabled">支付通道：</p>
        <p class="ml-52px">{{ detailData.payChannels || "" }}</p>
      </div>
      <div class="flex-start item">
        <p class="disabled">下单渠道：</p>
        <p class="ml-52px">{{ detailData.buyChannelsText || "" }}</p>
      </div>
      <div class="flex-start item">
        <p class="disabled">团体名称：</p>
        <p class="ml-52px">{{ detailData.distributorGroupName || "" }}</p>
      </div>
      <div class="flex-start item">
        <p class="disabled">团体类型：</p>
        <p class="ml-52px">{{ detailData.groupTypeName || "" }}</p>
      </div>
      <div class="flex-start item">
        <p class="disabled">总单状态：</p>
        <p class="ml-52px">{{ detailData.orderStatusText }}</p>
      </div>
      <div class="flex-start item">
        <p class="disabled">外部单号：</p>
        <p class="ml-52px">{{ detailData.outsideCode || "" }}</p>
      </div>
      <div class="flex-start item">
        <p class="disabled">总单开票状态：</p>
        <p class="ml-52px">{{ detailData.billingStatusText || "" }}</p>
      </div>
      <div class="flex-start item">
        <p class="disabled">总单开票时间：</p>
        <p class="ml-52px">
          {{ detailData.invoiceTime | date("yyyy-mm-dd hh:MM:ss") }}
        </p>
      </div>
      <div class="flex-start item mt-100rpx">
        <p class="align-start disabled">产品：</p>
        <div class="ml-52px">
          <div
            v-for="(item, index) in detailData.ticketInfoList"
            @click="checkTicketDetail(item.auxiliaryId)"
            :key="index"
            class="light-blue pv-1x"
          >
            {{ item.productName }}
          </div>
        </div>
      </div>
    </div>
  </qmp-layout>
</template>

<script>
import { searchReportOrderDetails } from "../../../api/order";
export default {
  name: "OrderDetail",
  data() {
    return {
      searchKey: "",
      detailData: {}
    };
  },
  created() {
    this.searchKey = this.$route.query.searchKey;
    this.loadData();
  },
  methods: {
    loadData() {
      searchReportOrderDetails({ id: this.searchKey })
        .then(res => {
          this.detailData = res;
        })
        .catch(() => {});
    },
    checkTicketDetail(id) {
      this.$router.push({
        path: "/search/TicketDetail",
        query: { searchKey: id }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.main {
  min-height: 100%;
  font-size: 22px;
  padding: 40px 66px;
  background: #fff;
  .item {
    margin-bottom: 30px;
    .disabled {
      color: #999;
      width: 154px;
      text-align: right;
    }
  }
}
.ml-52px {
  margin-left: 52px;
}
</style>
