<template>
  <qmp-layout title="产品详情">
    <div class="main">
      <div class="flex-start item">
        <p class="disabled">单品编号：</p>
        <p class="ml-52px">{{ resultDetail.auxiliaryId }}</p>
      </div>
      <div class="flex-start item">
        <p class="disabled">单品名称：</p>
        <p class="ml-52px">{{ resultDetail.productName }}</p>
      </div>
      <div class="flex-start item">
        <p class="disabled">下单时间：</p>
        <p class="ml-52px" v-if="resultDetail.createTime">
          {{ resultDetail.createTime | date("yyyy-mm-dd hh:MM") }}
        </p>
      </div>
      <div class="flex-start item">
        <p class="disabled">总单号：</p>
        <p class="ml-52px">{{ resultDetail.mainOrderId }}</p>
      </div>
      <div class="flex-start item">
        <p class="disabled">业务类型：</p>
        <p class="ml-52px">{{ resultDetail.orderBusinessTypeText }}</p>
      </div>
      <div class="flex-start item">
        <p class="disabled">子景区：</p>
        <p class="ml-52px">{{ resultDetail.ownerSubMerchantInfoName }}</p>
      </div>
      <div class="flex-start item">
        <p class="disabled">商户：</p>
        <p class="ml-52px">{{ resultDetail.ownerMerchantInfoName }}</p>
      </div>
      <div class="flex-start item">
        <p class="disabled">产品单价：</p>
        <p class="ml-52px">{{ resultDetail.totalAmount | money() }}</p>
      </div>
      <div class="flex-start item">
        <p class="disabled">产品实收：</p>
        <p class="ml-52px">{{ resultDetail.receivedAmount | money() }}</p>
      </div>
      <div class="flex-start item">
        <p class="disabled">产品状态：</p>
        <p class="ml-52px">{{ resultDetail.statusText }}</p>
      </div>
      <div class="flex-start item">
        <p class="disabled">产品有效期：</p>
        <div class="ml-52px" v-if="resultDetail.beginValidTime">
          {{ resultDetail.beginValidTime || "" | date("yyyy-mm-dd") }} ~
          {{ resultDetail.endValidTime || "" | date("yyyy-mm-dd") }}
        </div>
      </div>
      <div class="flex-start item">
        <p class="disabled">关联产品编号：</p>
        <p class="ml-52px">{{ resultDetail.parentAuxiliaryId }}</p>
      </div>
      <div class="flex-start item mt-1x">
        <p class="disabled">游客类型：</p>
        <p class="ml-52px">{{ resultDetail.touristType }}</p>
      </div>
      <div class="flex-start item">
        <p class="disabled">游客优惠：</p>
        <p class="ml-52px">{{ resultDetail.touristDiscount }}</p>
      </div>
      <div class="flex-start item">
        <p class="disabled">预约日期：</p>
        <p v-if="resultDetail.appointmentDate" class="ml-52px">
          {{ resultDetail.appointmentDate | date("yyyy-mm-dd") }}
        </p>
      </div>
      <div class="flex-start item">
        <p class="disabled">预约时段：</p>
        <div class="ml-52px">{{ resultDetail.appointmentTime || "" }}</div>
      </div>
      <div class="flex-start item">
        <p class="disabled">可核销次数：</p>
        <p class="ml-52px" v-if="resultDetail.canUseNum !== -1">
          {{ resultDetail.canUseNum }}
        </p>
        <p class="ml-52px" v-else>不限制</p>
      </div>
      <div class="flex-start item">
        <p class="disabled">已核销次数：</p>
        <p class="ml-52px">{{ resultDetail.useNum }}</p>
      </div>
      <div class="flex-start item">
        <p class="disabled">核销员：</p>
        <p class="ml-52px">{{ resultDetail.writeOffPersonName }}</p>
      </div>
      <div class="flex-start item">
        <p class="disabled">核销时间：</p>
        <p class="ml-52px" v-if="resultDetail.writeOffTime">
          {{ resultDetail.writeOffTime }}
        </p>
      </div>
      <div
        class="flex-start item"
        :class="{ 'mt-100rpx': resultDetail.whetherCombineProduct !== 2 }"
      >
        <p class="disabled">核销渠道：</p>
        <p class="ml-52px">{{ resultDetail.writeOffChannel }}</p>
      </div>
      <div
        class="flex-start item"
        v-if="resultDetail.whetherCombineProduct === 2"
        :class="{ 'mt-100rpx': resultDetail.whetherCombineProduct === 2 }"
      >
        <p class="align-start disabled">产品</p>
        <div class="text-r">
          <div class="light-blue pv-1x ml-52px">
            总单号：{{ resultDetail.mainOrderId }}
          </div>
          <div
            v-for="(item, index) in resultDetail.subProducts"
            @click="checkTicketDetail(item.auxiliaryId)"
            :key="index"
            class="light-blue pv-1x"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </qmp-layout>
</template>

<script>
import { orderMobilemanagerProductdetails } from "../../../api/search";
export default {
  name: "TicketDetail",
  data() {
    return {
      searchKey: "",
      resultDetail: {}
    };
  },
  created() {
    this.searchKey = this.$route.query.searchKey;
    this.loadData();
  },
  methods: {
    loadData() {
      orderMobilemanagerProductdetails({ keyword: this.searchKey })
        .then(res => {
          this.resultDetail = res[0];
        })
        .catch(() => {});
    },
    //订单详情- 点击票号显示门票详情内容
    checkTicketDetail(item) {
      this.searchKey = item;
      this.loadData();
    }
  }
};
</script>

<style lang="less" scoped>
.main {
  min-height: 100%;
  font-size: 22px;
  padding: 40px 66px;
  background: #fff;
  .item {
    margin-bottom: 30px;
    .disabled {
      color: #999;
      width: 154px;
      text-align: right;
    }
  }
}
.ml-52px {
  margin-left: 52px;
}
</style>
