<!--
  @name: 键盘带输入框组件
  @author: 郑佳娜
  @attr: {
    v-model: 输出键盘的值(必传)
    inputType: 输入框类型
    placeholder: 输入框placeholder
    isShowX: 是否显示X
    needVoice：是否需要读语音：（密码键盘，不需要读音）,默认是
    openIdcard：是否开启身份证读卡功能,默认是
  }
  @event:{
      ok:{   // 确定按钮的点击事件
          type:Function,
          default:null
      }
  } 
-->
<template>
  <div v-bind="$attrs" class="wrap">
    <slot>
      <div class="input-wrapper">
        <input
          class="input"
          :placeholder="placeholder"
          :type="inputType"
          :value="value"
          @input="keyUp"
          @keyup.enter="enter"
        />
        <i class="iconfont icon-qingchu" v-show="value" @click="change('')"></i>
      </div>
    </slot>
    <div class="content">
      <ul class="left" @click="numberClick">
        <li v-for="item in 9" :key="item" class="str" :data-value="item">
          {{ item }}
        </li>
        <li v-if="isShowX" class="str no-margin" data-value="X">X</li>
        <li
          :class="{ 'str no-margin': true, row2: isShowX, row3: !isShowX }"
          data-value="0"
        >
          0
        </li>
      </ul>
      <div class="right">
        <div class="back" @click="backSpace">
          <span class="iconfont icon-24gf-delete icon"></span>
        </div>
        <div class="btn" @click="confirm(value)">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  clearReadIdCard,
  clearScanCode,
  readIdCard,
  scanCode
} from "../../utils/hardware";

export default {
  name: "QmpKeyboard",

  props: {
    value: {
      type: String
    },
    inputType: {
      type: String,
      default: "text"
    },
    placeholder: {
      type: String,
      default: "请扫码或输入票号/取票号/订单号/手机号/身份证号"
    },
    // 是否显示X
    isShowX: {
      type: Boolean,
      default: true
    },
    // 是否需要读语音：（密码键盘，不需要读音）
    needVoice: {
      type: Boolean,
      default: true
    },
    // 是否开启身份证读卡功能
    openIdcard: {
      type: Boolean,
      default: true
    }
  },

  mounted() {
    // // 监听扫码，自动填充扫码得到的内容到键盘输入框
    // try {
    //   //开始监听扫描设备
    //   window.test.optScan(1);
    //   //桥接方法供Java调用
    //   window.payScan = this.payScan;
    // } catch (error) {
    //   console.log(error);
    // }
    // // 身份证读卡器
    // this.openIdcard && this.listen();
    scanCode(this.payScan);
    if (this.openIdcard) readIdCard(this.readIdCard);
  },

  beforeDestroy() {
    clearScanCode();
    clearReadIdCard();
  },

  methods: {
    readIdCard(res) {
      const cardInfo = JSON.parse(res);
      this.needVoice && this.$speak("读取成功");
      this.change(cardInfo.id);
      this.confirm(cardInfo.id);
    },
    payScan(code) {
      this.needVoice && this.$speak("扫描成功");
      this.change(code);
      this.confirm(code);
    },
    // 添加正则匹配
    keyUp(e) {
      let val = e.target.value;
      // const reg = this.isShowX ? /\d+(X|x)?/ : /\d+?/;
      // val = val.match(reg) ? val.match(reg)[0] : "";
      this.change(val);
    },
    // 数字点击赋值
    numberClick(e) {
      let val = e.target.dataset.value;
      if (!val) {
        return;
      }
      val = this.value + e.target.dataset.value;
      this.change(val);
    },
    // 删除最后一个字符
    backSpace() {
      let val = this.value.toString().substr(0, this.value.length - 1);
      this.change(val);
    },
    // 回车
    enter(e) {
      let val = e.target.value;
      this.confirm(val);
    },

    // 确定
    confirm(code) {
      if (code) {
        this.$emit("ok", code);
      } else {
        this.$message.error("请扫码或输入内容");
        this.needVoice && this.$speak("请扫码或输入内容");
      }
    },
    // 触发更改
    change(val) {
      this.$emit("input", val);
    }
  }
};
</script>

<style lang="less" scoped>
.input-wrapper {
  position: relative;
  .input {
    height: 100px;
    width: 100%;
    margin-bottom: 30px;
    border-radius: 8px;
    border: none;
    padding: 0 85px 0 32px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
    font-size: 28px;
    background: #fff;
    &::placeholder {
      font-size: 24px;
      color: #c2c2c2;
    }
    &:focus {
      outline: none;
      box-shadow: 0 0 4px 0 rgba(0, 193, 80, 0.9);
    }
  }
  .icon-qingchu {
    position: absolute;
    top: 30px;
    right: 30px;
    font-size: 32px;
    color: #c2c2c2;
    cursor: pointer;
  }
}

.content {
  display: flex;
  justify-content: space-between;

  .left {
    width: 75%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .str {
      height: 100px;
      width: calc(33% - 16px);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      margin-bottom: 24px;
      font-size: 56px;
      text-align: center;
      color: #3a3a3a;
      background: #ffffff;
      box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
      cursor: pointer;

      &.row2 {
        width: calc(66% - 4px);
      }

      &.row3 {
        width: 100%;
      }

      &.no-margin {
        margin-bottom: 0;
      }

      &:active {
        background-color: #aaaaaa;
      }
    }
  }

  .right {
    width: 25%;
    box-sizing: border-box;
    padding-left: 24px;

    .btn {
      width: 100%;
      height: 348px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      font-size: 36px;
      font-weight: normal;
      text-align: center;
      box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
      cursor: pointer;

      &:active {
        background: #008745;
      }
    }
  }

  .back {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
    text-align: center;
    cursor: pointer;

    .icon {
      font-size: 50px;
      color: #888888;
    }

    &:active {
      background-color: #aaaaaa;
    }
  }
}
</style>
