/**
 * MS-会员接口 3.0
 */
import http from "../utils/fetch"; // 公共请求方法
const prefix = `/api/member`; //服务前缀

/**
 * 会员
 */
// 【member003】会员增加/办理-马鹏浩
export const memberSaveOne = data => {
  return http.add(`${prefix}/save/one`, data);
};

// 修改会员资料-马鹏浩
export const memberUpdate = data => {
  return http.put(`${prefix}/update`, data);
};

// 查询会员详情/编辑会员回显
export const memberInfoOne = data => {
  return http.get(`${prefix}/info/one`, data);
};

//查看积分规则 -刘资涌
export const integralRule = data => {
  return http.get(`${prefix}/integral/rule`, data);
};

//查看会员等级详情 -刘资涌
export const memberLevel = data => {
  return http.get(`${prefix}/level`, data);
};

//订单折扣中的会员折扣 -刘资涌
export const cartCheckMemberLevel = data => {
  return http.get(`${prefix}/cart/check/member/level`, data);
};

/**
 * 会员详情下的记录
 */
// 【member005】资产记录 -刘资涌
export function balanceRecord(data) {
  return http.get(`${prefix}/balance/record`, data);
}
// 【member006】积分记录 -刘资涌
export function integralRecord(data) {
  return http.get(`${prefix}/integral/record`, data);
}

/**
 * YAPI 的接口
 */
// 获取简单会员，只传一个mobile （YAPI）
export function simpleMember(data) {
  return http.get(`${prefix}/simple/member`, data);
}
// 查询会员详情基本信息 - 罗子健 http://192.168.1.50:40001/project/41/interface/api/3904
export const orderOneMemberInfo = data => {
  return http.get(`${prefix}/evidence/one/member`, data);
};
// 【member006】积分记录 -刘资涌
export function getintegralRecord(data) {
  return http.get(`${prefix}/integral/record/info`, data);
}
// 成长值记录 -刘资涌
export function growthRecord(data) {
  return http.get(`${prefix}/growth/record/info`, data);
}
// 会员-查询会员资产明细-罗子健
export const findMemberProperty = data => {
  return http.get(`${prefix}/find/member/property`, data);
};
// 会员-根据会员id查询会员资产记录(充值退款明细列表)-罗子健
export const findMemberAssetsInfo = data => {
  return http.get(`${prefix}/find/member/assets/info`, data);
};
// 查询会员详情基本信息 - 罗子健 http://192.168.1.50:40001/project/41/interface/api/4001
export const memberRefundBalance = data => {
  return http.get(`${prefix}/member/refund/balance`, data);
};
// 会员-.会员详情单个资产退款-罗子健 http://192.168.1.50:40001/project/41/interface/api/3958
export const memberAssetsInfo = data => {
  return http.put(`${prefix}/member/assets/info`, data);
};
