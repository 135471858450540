<template>
  <div>
    <div class="qr-wrap flex-center">
      <img
        src="https://obs-qmp-android.obs.cn-south-1.myhuaweicloud.com/qumaipiao_k1/image/gif/saomaqupiao.gif"
        alt="qrcode"
      />
    </div>
    <p class="text-c font-32">将门票二维码对准右下方扫描器</p>
    <p class="text-c font-32 bold mt-2x">开始查询</p>
    <qmp-keyboard class="keyboard" v-model="code" @ok="keyboardOk" />
  </div>
</template>

<script>
import { orderOneMemberInfo } from "../../../api/member";
import QmpKeyboard from "../../../components/common/QmpKeyboard.vue";
import { orderMobilemanagerProductdetails } from "../../../api/search";
import { searchReportOrderDetails } from "../../../api/order";
export default {
  name: "SearchIndex",
  components: {
    QmpKeyboard
  },
  data() {
    return {
      memberId: "",
      code: ""
    };
  },

  created() {
    this.$speak("请将取票二维码对准下方扫描器或将IC卡放置在下方读卡器");
  },

  methods: {
    keyboardOk(val) {
      if (!val) {
        this.$message.warning("请输入相关信息");
        return;
      }
      // 订单号
      if (/^\d{19}$/.test(val)) {
        searchReportOrderDetails({ id: val })
          .then(res => {
            if (res) {
              this.$router.push({
                path: "/search/OrderDetail",
                query: { searchKey: val }
              });
            } else {
              this.$message.warning("未查询到相关信息");
              this.$speak("未查询到相关信息");
            }
          })
          .catch(() => {});

        // 票号
      } else if (/^\d{12}$/.test(val)) {
        orderMobilemanagerProductdetails({ keyword: val })
          .then(res => {
            if (res.length) {
              this.$router.push({
                path: "/search/TicketDetail",
                query: { searchKey: val }
              });
            } else {
              this.$message.warning("未查询到相关信息");
              this.$speak("未查询到相关信息");
            }
          })
          .catch(() => {});
      } else {
        orderOneMemberInfo({ evidence: val }).then(res => {
          this.memberId = res.id;
          // 会员
          this.$router.push({
            path: "/member",
            query: { searchKey: this.memberId }
          });
        });
      }
    }
  }
};
</script>

<style scoped lang="less">
.qr-wrap {
  img {
    width: 550px;
    min-height: 423px;
  }
}
.font-32 {
  font-size: 32px;
  line-height: 38px;
}
.keyboard {
  width: 660px;
  margin: 42px auto 0;
}
</style>
