import http from "../utils/fetch"; // 公共请求方法
const prefix = `/api/order`; //服务前缀

/**
 *订单支付
 */
//付款码支付-胡汉杰
export const tradePaymentInfoMicropay = data => {
  return http.get(`${prefix}/trade/payment/info/micropay`, data);
};
//获取支付状态-胡汉杰
export const tradePaymentInfoStatus = (data, config) => {
  return http.get(`${prefix}/trade/payment/info/status`, data, config);
};

// order004-查询门票账号概况-云玉峰
export const findTicketAssetsList = data => {
  return http.get(`${prefix}/find/ticket/assets/list`, data);
};
// order005-查询项目套餐概况(分页)-云玉峰
export const findTicketPlayitempackagePage = data => {
  return http.get(`${prefix}/find/ticket/playitempackage/page`, data);
};

// order007-查询产品详情信息-云玉峰
export const findOrderDetailProductDetail = data => {
  return http.get(`${prefix}/find/order/detail/product/detail`, data);
};

// order020-门票激活查询-云玉峰
export const findTicketActivateInfo = data => {
  return http.get(`${prefix}/find/ticket/activate/info`, data);
};

// order020-门票激活-云玉峰
export const findTicketActivate = data => {
  return http.post(`${prefix}/ticket/activate`, data);
};

// order029-补录人脸-云玉峰
export const ticketActivateFace = data => {
  return http.post(`${prefix}/ticket/activate/face`, data);
};

// order019-取票-云玉峰
export const ticketPrint = data => {
  return http.post(`${prefix}/ticket/print`, data);
};

// order021-门票补办查询-云玉峰
export const findTicketReissueInfo = data => {
  return http.get(`${prefix}/find/ticket/reissue/info`, data);
};

// //order036-订单线下支付-云玉峰
// export const payOfflineOrder = data => {
//   return http.post(`${prefix}/pay/offline/order`, data);
// };

// 【order120】门票充值的产品列表-刘院民  YAPI
export const ticketRechargeProductList = data => {
  return http.get(`${prefix}/ticket/recharge/product/list`, data);
};

// order013-查询门票核销记录(分页)-云玉峰
export const findTicketWriteOffInfoPage = data => {
  return http.get(`${prefix}/find/ticket/writeoff/info/page`, data);
};

// order025-订单退款校验-云玉峰
export const refundCheck = data => {
  return http.post(`${prefix}/refund/check`, data);
};

// order026-订单退款-云玉峰
export const refund = data => {
  return http.post(`${prefix}/refund`, data);
};

// 【order121】门票推荐加购的产品列表-刘院民  YAPI
export const ticketAddProductList = data => {
  return http.get(`${prefix}/ticket/add/product/list`, data);
};

// 【order119】快速通行服务-刘院民  YAPI
export const ticketFastWithProductList = data => {
  return http.get(`${prefix}/ticket/fast/with/product/list`, data);
};

// 【order118】综合套票包含子产品列表-刘院民  YAPI
export const ticketPackageProductList = data => {
  return http.get(`${prefix}/ticket/package/product/list`, data);
};

//-订单下单后直接出票打印-云玉峰
export const orderPrint = data => {
  return http.post(`${prefix}/order/print`, data);
};

// 新版支付相关
//付款码支付-玉峰 （支付重构版本）
export const tradePaymentInfoMicropayV2 = (data, config) => {
  return http.get(`${prefix}/trade/payment/info/micropay/v2`, data, config);
};
//order036-订单线下支付-云玉峰
export const payOfflineOrder = data => {
  return http.post(`${prefix}/pay/offline/order`, data);
};
// 订单-抵扣支付
export const payDeductionOrder = data => {
  return http.post(`${prefix}/pay/deduction/order`, data);
};
// 订单-挂账支付
export const payOncreditOrder = data => {
  return http.post(`${prefix}/pay/oncredit/order`, data);
};
// 订单-押金支付
export const payCashpledgeOrder = data => {
  return http.post(`${prefix}/pay/cashpledge/order`, data);
};
// 根据订单号查询 报表数据
export const searchReportOrderDetails = data => {
  return http.get(`${prefix}/mini/report/order/detail`, data);
};
// 新版订单  整单（退单)校验查询-云玉峰 - http://192.168.1.50:40001/project/41/interface/api/3967
export const refundOrderCheck = data => {
  return http.post(`${prefix}/refund/order/check`, data);
};
// 新版订单  整单（退单)-云玉峰 - http://192.168.1.50:40001/project/41/interface/api/3968
export const refundOrder = data => {
  return http.post(`${prefix}/refund/order`, data);
};
// 【order128】极速下单 http://192.168.1.50:40001/project/41/interface/api/3891
export const createQuickOrder = data => {
  return http.post(`${prefix}/create/quick/order`, data);
};
// 取票通知(新版票务) http://192.168.1.50:40001/project/41/interface/api/4342
export const productPrint = data => {
  return http.post(`${prefix}/product/print`, data);
};
